import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <>
    <Helmet encodeSpecialCharacters={false}>
      <title>Form submission</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="p-10 text-sm">
      <p>Thanks for your submission.</p>
    </div>
  </>
);
